import { MessageKey } from "./translate";
import messagesDe from "./translations/de.json";
import messagesEn from "./translations/en.json";
import { SiteLocale } from "../__generated__/types/graphql-codegen";

export const allLocaleMessages: {
	[key in SiteLocale]: Record<MessageKey, string>;
} = {
	de: messagesDe,
	en: messagesEn,
};

export const getTranslatedString = (id: MessageKey, locale: SiteLocale) =>
	allLocaleMessages[locale][id];

import { Crumb } from "../components/Breadcrumb/Breadcrumb";
import { CategoriesModelFragment } from "../__generated__/types/graphql-codegen";
import { joinPaths, truthy } from "./misc";

type CategoryLevel = 1 | 2 | 3;
export type Category = {
	id: string;
	name: string;
	slug: string;
	level: CategoryLevel;
	originalIndex?: number;
};

export const orderCategories = (
	categories: Array<CategoriesModelFragment>,
): Array<Category> => {
	const foundLevels: Array<CategoryLevel> = [];
	const normalized: Array<Category> = [];

	for (
		let originalIndex = 0;
		originalIndex < categories.length;
		originalIndex++
	) {
		const c = categories[originalIndex];

		if (!c?.level || !c.name || !c.slug) {
			continue;
		}

		const level = Math.max(
			1,
			Math.min(parseInt(c.level), 3),
		) as CategoryLevel;

		if (level > 3 || level < 1 || foundLevels.includes(level)) {
			continue;
		}

		foundLevels.push(level);

		normalized.push({
			...c,
			level,
			originalIndex,
		} as Category);
	}

	return normalized.filter(truthy).sort((a, b) => {
		// we can do this, because we can be sure, there will be no more than 3 categories in this field
		// so we are sorting numbers between 10 and 32 and can just compare them with <
		const aNumeric = a.level * 10 + (a.originalIndex ?? 0);
		const bNumeric = b.level * 10 + (b.originalIndex ?? 0);

		if (aNumeric === bNumeric) {
			return 0;
		}

		return aNumeric < bNumeric ? -1 : 1;
	});
};

export const getCategoriesForBreadcrumbs = (
	categories: Array<Category>,
	options: {
		baseLabel: string;
		prefixPath: string;
	},
): Array<Crumb> => {
	return [
		{ label: options.baseLabel, href: "/", level: 1 },
		...categories.map(({ name, slug }, i, a) => {
			// cat1/cat2/…
			const previous = a.slice(0, i).map((p) => p.slug);

			const paths = [options.prefixPath, ...previous, slug].filter(
				truthy,
			);

			const href = joinPaths("/", ...paths);

			return {
				label: name,
				level: i + 2, // home is 1 and then correct for zero based index
				href,
			};
		}),
	].filter(truthy);
};

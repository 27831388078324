import { StyleFunctionProps } from "@chakra-ui/theme-tools";
import formatISODuration from "date-fns/formatISODuration";
import { BreakpointKeys } from "./types";

/**
 * typesafe Boolean()
 */
type Truthy<T> = T extends false | "" | 0 | null | undefined ? never : T; // from lodash

export const truthy = <T>(value: T): value is Truthy<T> => {
	return Boolean(value);
};

export const px2rem = (val: number): string => `${val / 16}rem`;

// Scheme: https://tools.ietf.org/html/rfc3986#section-3.1
// Absolute URL: https://tools.ietf.org/html/rfc3986#section-4.3
const ABSOLUTE_URL_REGEX = /^[A-Za-z][\d+.A-Za-z-]*?:/;

// Windows paths like `c:\`
const WINDOWS_PATH_REGEX = /^[A-Za-z]:\\/;

export const isAbsoluteURL = (url: string) => {
	if (typeof url !== "string") {
		throw new TypeError(`Expected a \`string\`, got \`${typeof url}\``);
	}

	if (WINDOWS_PATH_REGEX.test(url)) {
		return false;
	}

	return ABSOLUTE_URL_REGEX.test(url);
};

/**
 * promisified setTimeout
 */
export const timeout = async (ms: number) => {
	return new Promise((resolve) => setTimeout(resolve, ms));
};

/**
 * escapes special chars in a string to use it for a regex
 */
const escapeRegex = (str: string): string =>
	str.replace(/[$()*+./?[\\\]^{|}-]/g, "\\$&");

/**
 * removes specific characters from the beginning and/or end of a string
 * cf. https://www.php.net/manual/en/function.trim.php
 */
export const trimChars = (
	str: string,
	chars: string | Array<string> = "",
): string => {
	// trim whitespace if not otherwise defined
	if (typeof chars === "string" && chars.trim() === "") {
		return str.trim();
	}

	chars =
		typeof chars === "string"
			? Array.from(new Set(chars.split("")))
			: Array.from(new Set(chars));

	const searchGroup = escapeRegex(chars.join(""));

	const reStart = new RegExp(`^[${searchGroup}]+`, "g");
	const reEnd = new RegExp(`[${searchGroup}]+$`, "g");

	return str.replace(reStart, "").replace(reEnd, "");
};

/**
 * trim slashes from a string
 */

export const trimSlashes = (str: string): string => trimChars(str, "/");

/**
 * join parameters with single slashes (URLs, file paths...)
 */
export const joinPaths = (...parts: Array<string | null | undefined>): string =>
	parts.filter(truthy).map(trimSlashes).join("/");

/**
 * is a server side rendering context
 */

export const isSSR = !(
	typeof window !== "undefined" && window.document.createElement
);

/**
 * typesafer replacement for Object.fromEntries
 */
export const fromEntries = <T>(
	entries: Array<[string, T]>,
): Record<string, T> => {
	return entries.reduce(
		(acc, [key, value]) => ({ ...acc, [key]: value }),
		{},
	);
};

export const convertHexToRgb = (
	hexString: string,
): { r: number; g: number; b: number } | undefined => {
	hexString = hexString.replace("#", "");

	if (hexString.length === 6) {
		return {
			r: parseInt(hexString.slice(0, 2), 16),
			g: parseInt(hexString.slice(2, 4), 16),
			b: parseInt(hexString.slice(4, 6), 16),
		};
	}

	if (hexString.length === 3) {
		const hexArray = hexString.split("");

		if (hexArray.length !== 3) {
			return undefined;
		}

		try {
			const numArray = hexArray.map((c: string) => {
				const parsed = parseInt(c + c, 16);

				if (isNaN(parsed) || typeof parsed !== "number") {
					throw new Error("Invalid color hex string");
				}

				return parsed;
			});

			return {
				// eslint-disable-next-line @typescript-eslint/non-nullable-type-assertion-style
				r: numArray[0] as number,
				// eslint-disable-next-line @typescript-eslint/non-nullable-type-assertion-style
				g: numArray[1] as number,
				// eslint-disable-next-line @typescript-eslint/non-nullable-type-assertion-style
				b: numArray[2] as number,
			};
		} catch (_) {
			return undefined;
		}
	}

	return undefined;
};

/**
 * convert hex value to yiq to identify color differences and determine if it's a dark color
 * values based on https://www.w3.org/TR/AERT/#color-contrast
 */
export const isDarkColor = (hexcolor: string) => {
	const hex = convertHexToRgb(hexcolor);

	if (!hex) {
		return false;
	}

	const { r, g, b } = hex;

	const yiq = (r * 299 + g * 587 + b * 114) / 1000;

	return yiq < 128;
};

export const convertBpToMediaQuery = (
	bp: BreakpointKeys,
	theme: StyleFunctionProps["theme"],
) => {
	return `@media screen and (min-width: ${theme.breakpoints[bp]})`;
};

/**
 * portion an array into defined sizes + a rest array:
 * e.g. `const arr = [_,_,_,_,_,_,_,_,_,_,_,_,_,_,] // length 14`
 * `sliceArray(arr, 3, 6) === [[_,_,_], [_,_,_,_,_,_], [_,_,_,_,_]]` // lengths: 3, 6, 5
 *
 * if the sum of the slices exceed the input array, we just return what's there and skip the rest
 */

export const sliceArray = <T>(
	arr: Array<T>,
	...sliceSizes: Array<number>
): Array<Array<T>> => {
	sliceSizes = [...sliceSizes, Number.MAX_SAFE_INTEGER];

	const rest = [...arr];
	const slices = [];

	for (const size of sliceSizes) {
		if (rest.length <= size) {
			slices.push(rest);

			return slices;
		}

		slices.push(rest.slice(0, size));
		rest.splice(0, size);
	}

	return slices;
};

export const getHostname = (url: string) => {
	try {
		return new URL(url).hostname;
	} catch (_) {
		return null;
	}
};

/**
 * converts a number of words of a text to an ISO8601 duration (PT5M = 5 minutes)
 *
 * > on average, most adults read at a rate of about 200 - 250 wpm
 * — https://ezinearticles.com/?What-is-the-Average-Reading-Speed-and-the-Best-Rate-of-Reading?&id=2298503
 * @param wordCount
 * @param wordsPerMinute
 */
export const wordsPerMinuteToISO8601Duration = (
	wordCount: number,
	wordsPerMinute = 250,
) => {
	let minutes = Math.ceil(wordCount / wordsPerMinute);
	const hours = Math.floor(minutes / 60);

	minutes = minutes % 60;

	return formatISODuration({ hours, minutes });
};

type DatesInput = {
	_draftFirstPublishedAt?: string | null;
	_draftLastUpdatedAt?: string | null;
	_firstPublishedAt?: string | null;
	_lastUpdatedAt?: string | null;
};

export type NormalizedDates = {
	published?: string;
	lastmod?: string;
};

export const getNormalizedDates = ({
	_draftFirstPublishedAt,
	_draftLastUpdatedAt,
	_firstPublishedAt,
	_lastUpdatedAt,
}: DatesInput): NormalizedDates => {
	return {
		published: _firstPublishedAt ?? _draftFirstPublishedAt ?? undefined,
		lastmod: _lastUpdatedAt ?? _draftLastUpdatedAt ?? undefined,
	};
};

export const duplicateRecordsById = <T extends { id: string | number }>(
	a: T,
	i: number,
	all: Array<T>,
): boolean => all.findIndex((f) => f.id === a.id) === i;

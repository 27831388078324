/**
 * capitalize the first character of a string
 */

import { truthy } from "./misc";

export const capitalizeFirstLetter = (s: string): string =>
	s.charAt(0).toUpperCase() + s.slice(1).toLowerCase();

export const lowercaseFirstLetter = (s: string): string =>
	s.charAt(0).toLowerCase() + s.slice(1);

/**
 * capitalize every word in a string
 */

export const capitalize = (s: string): string =>
	s.split(" ").map(capitalizeFirstLetter).join(" ");

/**
 * className helper
 */

type ClassNameRule = string | Record<string, boolean>;
type ClassNameHelperArgs = Array<ClassNameRule | null | undefined>;

const classNameHelper = (...args: ClassNameHelperArgs): string => {
	const resolved = args.filter(truthy).map((cn) => {
		const isString = typeof cn === "string";

		if (isString) {
			return cn;
		}

		return Object.entries<boolean>(cn)
			.map(([k, v]) => (v ? k : null))
			.filter(truthy)
			.join(" ");
	});

	return resolved.filter(truthy).join(" ");
};

export const cn = classNameHelper;

// we don't need a real ISIN validation (it contains a checksum), this will do…
export const isISIN = (isin: string): boolean => {
	if (isin.length !== 12) {
		return false;
	}

	// but we can borrow their regex 👌
	// https://github.com/JonaMX/js-isin-validator/blob/master/lib/index.js#L7
	const isinRegex = /^([A-Za-z]{2})((?![A-Za-z]{10}\b)[\dA-Za-z]{9})(\d)$/;

	if (!isinRegex.test(isin)) {
		return false;
	}

	return true;
};

export const truncateString = (str: string, length = 100): string => {
	const truncated = str
		.split(" ")
		.reduce<string | null>((sentence, nextWord): string => {
			if (sentence === null) {
				return nextWord;
			}

			if (sentence.length + 1 + nextWord.length < length) {
				return sentence + " " + nextWord;
			}

			return sentence;
		}, null);

	if (truncated && truncated.length < str.length) {
		return truncated + " …";
	}

	return str;
};

export const validateWords = (potentialWords: string | undefined) => {
	const result = potentialWords?.match(/^[\s\w#]*$/);

	return result ?? false;
};

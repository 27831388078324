import { formatInTimeZone } from "date-fns-tz";
import { GetStaticPaths, GetStaticPropsResult } from "next";
import { BrandSlug, TeaserSectionType } from "../portalstack.config";
import { Layout } from "../brandComponents/shared/HomePage/helpers";
import { TeaserVariant } from "../components/teasers/utils";
import { getTranslatedString } from "../i18n/allLocales";
import { BrandSettings } from "../lib/brandSettings";
import { UrlPrefixMessageKey } from "../types/misc";
import {
	GetAllArticlePathsQuery,
	SiteLocale,
	Tag,
} from "../__generated__/types/graphql-codegen";
import { joinPaths, truthy } from "./misc";
import { orderCategories } from "./orderCategories";
import { isISIN } from "./strings";
import { Article } from "./types";

export const notFoundStaticProps: GetStaticPropsResult<any> = {
	revalidate: 60,
	notFound: true,
};

export const getStaticPathsOnlyIsr: GetStaticPaths = async () => {
	return {
		paths: [],
		fallback: "blocking",
	};
};

export type ReadCanonicalArticleUrlParams = {
	___urlPrefixMessageKey?: UrlPrefixMessageKey;
	_firstPublishedAt?: string | null;
	published?: string | null;
	slug?: string | null;
	company?: NonNullable<
		GetAllArticlePathsQuery["allArticles"]
	>[number]["company"];
	categories?: NonNullable<
		GetAllArticlePathsQuery["allArticles"]
	>[number]["categories"];
};

export type CanonicalArticleUrlParams = {
	___urlPrefixMessageKey?: UrlPrefixMessageKey;
	slug: string;
	_firstPublishedAt: string;
	categories?: Array<string>;
	company?: {
		isin?: string;
		slug?: string;
	};
	targetBrandSlug?: BrandSlug;
};

export const getCanonicalSlugParts = (
	{
		___urlPrefixMessageKey,
		targetBrandSlug,
		slug,
		company,
		_firstPublishedAt,
		categories,
	}: CanonicalArticleUrlParams,
	locale: SiteLocale,
	currentBrandSlug?: BrandSlug,
): Array<string> => {
	const typeUrlPrefix = getTranslatedString(
		___urlPrefixMessageKey ?? "urlSlugArticle",
		locale,
	);

	const disableTimestamp = Boolean(
		process.env.MMG_DISABLE_TIMESTAMP_IN_ARTICLE_URLS,
	);

	const dateTime = _firstPublishedAt
		? formatInTimeZone(
				_firstPublishedAt,
				"Europe/Berlin",
				disableTimestamp ? "y-MM-dd" : "y-MM-dd-HHmm",
		  )
		: "draft";

	const companyPart =
		company?.slug && company.isin
			? [company.slug, company.isin].join("-")
			: null;

	const slugPart = [slug, dateTime].join("--");

	const isCrossPromo =
		targetBrandSlug !== undefined &&
		currentBrandSlug !== undefined &&
		targetBrandSlug !== currentBrandSlug;

	const parts: Array<string> = [
		isCrossPromo && `/crp/${targetBrandSlug}`,
		!isCrossPromo && typeUrlPrefix,
		...(categories ?? []),
		companyPart,
		slugPart,
	].filter(truthy);

	return parts;
};

export const getCanonicalHref = (
	urlParams: CanonicalArticleUrlParams,
	locale: SiteLocale,
	currentBrandSlug?: BrandSlug,
) =>
	"/" +
	joinPaths(...getCanonicalSlugParts(urlParams, locale, currentBrandSlug));

export const getArticleSlugArrayByArticle = (
	article: ReadCanonicalArticleUrlParams,
	locale: SiteLocale,
): Array<string> => {
	const props = normalizeArticleToCanonicalProps(article);

	return getCanonicalSlugParts(props, locale);
};

export const getCanonicalArticleHrefByArticle = (
	article: ReadCanonicalArticleUrlParams,
	locale: SiteLocale,
): string => {
	const slugParts = getArticleSlugArrayByArticle(article, locale);

	return "/" + joinPaths(...slugParts);
};

export const normalizeArticleToCanonicalProps = ({
	_firstPublishedAt,
	published,
	categories: categoriesRaw,
	company,
	slug,
	___urlPrefixMessageKey,
}: ReadCanonicalArticleUrlParams): CanonicalArticleUrlParams => {
	if (!slug) {
		throw new Error(`no article URL slug found`);
	}

	const categories = orderCategories(categoriesRaw ?? []).map((c) => c.slug);

	return {
		slug,
		_firstPublishedAt: _firstPublishedAt ?? published ?? "",
		___urlPrefixMessageKey,
		categories,
		company: {
			slug: company?.slug ?? undefined,
			isin: company?.isin ?? undefined,
		},
	};
};

const buildTitle = (...parts: Array<string | undefined | null>) =>
	parts.filter(truthy).join(` ${process.env.MMG_PAGE_TITLE_SEP ?? "|"} `);

export const getTitle = (
	brandSettings: BrandSettings,
	overrideMetaTags?: Array<Tag>,
	prefix?: string,
): string => {
	const suffix = brandSettings.label ?? brandSettings.slug ?? "";

	const metaTags = overrideMetaTags ?? brandSettings.seo;

	const seoTitle =
		metaTags.find((t) => t.tag === "title")?.content ?? undefined;

	if (seoTitle) {
		if (seoTitle.includes(suffix)) {
			return buildTitle(prefix, seoTitle);
		}

		return buildTitle(prefix, seoTitle, suffix);
	}

	return buildTitle(prefix, suffix);
};

const teaserImageFallbackToCompanyImage = (
	article: Article,
	type: TeaserVariant,
	// we disable this line because the switch is already exhaustive and when someone
	// adds a teaser variant we need the @typescript-eslint/switch-exhaustiveness-check to alert them
	// eslint-disable-next-line consistent-return
): Article => {
	const hasImage = Boolean(
		article.image ?? article.imageHero ?? article.imageOutlined,
	);

	if (hasImage) {
		return { ...article, companyImage: null };
	}

	switch (type) {
		case "overview":
		case "text-only":
		case "single-eyecatcher":
		case "list":
			return { ...article, companyImage: null };
		case "hero":
			return {
				...article,
				imageHero: article.companyImage?.imageHero ?? null,
				companyImage: null,
			};
		case "regular":
			return {
				...article,
				image: article.companyImage?.image ?? null,
				companyImage: null,
			};
		case "outlined":
			return {
				...article,
				imageOutlined: article.companyImage?.imageOutlined ?? null,
				companyImage: null,
			};
	}
};

export const cleanupUnusedImageData = (
	articles: Array<Article>,
	layout: Array<Layout>,
) => {
	const teaserTypes: Record<TeaserSectionType, Array<TeaserVariant>> = {
		SectionHomeTeaserHeroRecord: ["hero"],
		SectionHomeTeaserQuartetRecord: [
			"regular",
			"regular",
			"regular",
			"regular",
		],
		SectionHomeTeaserSingleRecord: ["regular"],
		SectionHomeTeaserThreeColumnsTradingviewRecord: [
			"outlined",
			"text-only",
			"text-only",
			"text-only",
		],
		SectionHomeTeaserTwoByTwoRecord: [
			"text-only",
			"text-only",
			"text-only",
			"text-only",
		],
	};

	const teaserVariants = layout
		.map((l) => {
			return teaserTypes[l.__typename as TeaserSectionType];
		})
		.filter(truthy)
		.flat();

	// we disable this line because the switch is already exhaustive and when someone
	// adds a teaser variant we need the @typescript-eslint/switch-exhaustiveness-check to alert them

	// eslint-disable-next-line consistent-return, array-callback-return
	return articles.map((article, i) => {
		const currentVariant = teaserVariants[i] ?? "regular";

		switch (currentVariant) {
			case "overview":
			case "text-only":
			case "single-eyecatcher":
			case "list":
				return {
					...article,
					imageHero: null,
					imageOutlined: null,
					image: null,
				};
			case "hero":
				return teaserImageFallbackToCompanyImage(
					{
						...article,
						imageOutlined: null,
						image: null,
					},
					"hero",
				);
			case "regular":
				return teaserImageFallbackToCompanyImage(
					{
						...article,
						imageOutlined: null,
						imageHero: null,
					},
					"regular",
				);
			case "outlined":
				return teaserImageFallbackToCompanyImage(
					{
						...article,
						image: null,
						imageHero: null,
					},
					"outlined",
				);
		}
	});
};

export const incompleteArticlePathRedirect = (
	path: string,
	locale: SiteLocale,
) => {
	const slugParts = path.split("/");
	// eslint-disable-next-line @typescript-eslint/non-nullable-type-assertion-style
	const lastFilter = slugParts.pop() as string;

	const possibleISIN = [...lastFilter.split("-")].pop();
	const isCompanyFilter =
		lastFilter.indexOf("-") && possibleISIN && isISIN(possibleISIN);
	const companyUrlPrefix = getTranslatedString("urlSlugStock", locale);

	// it's a company filter
	if (isCompanyFilter) {
		return {
			redirect: {
				destination: "/" + joinPaths(companyUrlPrefix, lastFilter), // the "canonical" URL for company overview pages
				permanent: true,
			},
		};
	}

	// it's a category filter
	const categoryUrlPrefix = getTranslatedString("urlSlugCategories", locale);

	return {
		redirect: {
			destination:
				"/" + joinPaths(categoryUrlPrefix, ...slugParts, lastFilter),
			permanent: true,
		},
	};
};
